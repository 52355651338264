import React from "react";
import Counter from "../Components/Counter";
import Feedback from "../Components/Feedback";
import Faqs from "../Components/Faqs";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
function About() {
  return (
    <>
     <Helmet>
        <title>ABOUT US</title>
      </Helmet>
      <section
        class="hero-wrap hero-wrap-2 about-page-top-img"
        data-stellar-background-ratio="0.5"
      >
        <div class="overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text align-items-end">
            <div class="col-md-9 text-start  pb-5">
              <p class="breadcrumbs mb-2">
                <span class="mr-2">
                  <NavLink to="/">
                    Home <i class="ion-ios-arrow-forward"></i>
                  </NavLink>
                </span>{" "}
                <span>
                  About us <i class="ion-ios-arrow-forward"></i>
                </span>
              </p>
              <h1 class="mb-0 bread">About Us</h1>
            </div>
          </div>
        </div>
      </section>
      <section class="ftco-section ftco-no-pt bg-light">
        <div class="container">
          <div class="row d-flex no-gutters">
            <div class="col-md-6 d-flex">
              <div class="img home-about-image img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0"></div>
            </div>
            <div class="col-md-6 pl-md-5 py-md-5">
              <div class="heading-section pl-md-4 pt-md-5">
                <span class="subheading">Welcome to Accounting</span>
                <h2 class="mb-4">We Are the Best Accounting Agency</h2>
                <p>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                  Separated they live in Bookmarksgrove right at the coast of
                  the Semantics, a large language ocean.
                </p>
                <p>
                  A small river named Duden flows by their place and supplies it
                  with the necessary regelialia. It is a paradisematic country,
                  in which roasted parts of sentences fly into your mouth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
     
     
     
    </>
  );
}
export default About;
