import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import NewsAndBlog from "../Components/NewsAndBlog";
import Counter from "../Components/Counter";
import Feedback from "../Components/Feedback";
import Faqs from "../Components/Faqs";
import Packages from "../Components/Packages";
import Axios from "axios";
import { BaseURL } from "../Components/Api";
import gif from '../Images/Grey Isometric Tax And Consulting Services Instagram Post.gif'
import img from '../Images/123456.webp'

import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function Home() {
  const [sliderDetail, setSliderDetail] = useState([]);
  const [latestServices, setLatestServices] = useState([]);
  const baseurl = BaseURL();
  const optionstop = {
    items: 1, // Number of items to display
    loop: true, // Loop through items
    margin: 10, // Margin between items
    autoplay: true, // Enable autoplay
    autoplayTimeout: 3000, // Autoplay interval in milliseconds
    animateOut: 'fadeOut', // Fade out animation
    animateIn: 'fadeIn', // Fade in animation
    smartSpeed: 450, // Speed of the transition

  };

  useEffect(() => {
    getSliderDetail();
    getLAtestServices();
  }, []);
  const getSliderDetail = async () => {
    try {
      const response = await Axios.get(baseurl + '/api/slider');
      if (response.data.status === true) {
        setSliderDetail(response.data.data);
      }
    } catch (error) {
      console.log(error)
    }

  }
  const getLAtestServices = async () => {
    try {
      const response = await Axios.get(baseurl + '/api/services/latest');
      // console.log(response);
      if (response.data.status === true) {
        setLatestServices(response.data.data);
      }
    } catch (error) {
      console.log(error)
    }

  }
  return (
    <>
      <Helmet>
        <title>HOME</title>
      </Helmet>
      <div className="hero-wrap top-home-slider">
        <div className="home-slider owl-carousel">
          {sliderDetail.length !== 0 ? (
            <OwlCarousel className="owl-theme" {...optionstop}>
              {sliderDetail.map((item, index) => (
                <div key={index} className="item">
                  <div className="slider-item  " style={{ backgroundImage: `url(${item.media[0]?.original_url})` }}  >
                    <div className="overlay"></div>
                    <div className="container">
                      <div className="row no-gutters slider-text align-items-center justify-content-center">
                        <div className="col-md-8 ">
                          <div className="text w-100 text-center">
                            <h2>{item.short_title}</h2>
                            <h1 className="mb-4">{item.long_title}</h1>
                            <p><NavLink to="/contact" target="blank" className="btn btn-white">Connect with us</NavLink></p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              ))}
            </OwlCarousel>

          ) : (
            <OwlCarousel className="owl-theme" {...optionstop}>
              <div className="item">
                <div className="slider-item slider-img-3" >
                  <div className="overlay"></div>
                  <div className="container">
                    <div className="row no-gutters slider-text align-items-center justify-content-center">
                      <div className="col-md-8 ">
                        <div className="text w-100 text-center">
                          <h2></h2>
                          <h1 className="mb-4"></h1>
                          {/* <p><NavLink to="/contact" target="blank" className="btn btn-white">Connect with us</NavLink></p> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OwlCarousel>
          )}

        </div>
      </div>

      <section className="ftco-section ftco-no-pt bg-light text-start d-none">
        <div className="container">
          <div className="row d-flex no-gutters">
            <div className="col-md-6 d-flex">
              <div className="img  img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0">
                <img style={{ width: '100%' }} src={gif}></img>
              </div>
            </div>
            <div className="col-md-6 pl-md-5 py-md-5">
              <div className="heading-section pl-md-4 pt-md-5">
                <span className="subheading">Welcome to Accounting</span>
                <h2 className="mb-4">We Are the Best Accounting Agency</h2>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="flaticon-wealth"></span>
                </div>
                <div className="text pl-4">
                  <h4>Market Analysis</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="flaticon-accountant"></span>
                </div>
                <div className="text pl-4">
                  <h4>Accounting Advisor</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="flaticon-teamwork"></span>
                </div>
                <div className="text pl-4">
                  <h4>General Consultancy</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="flaticon-accounting"></span>
                </div>
                <div className="text pl-4">
                  <h4>Structured Assestment</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container" style={{marginTop:'5em'}}>
        <div className="row mt-4" >
          <div className="col-md-6">
            <img className="rounded" src={img} style={{ height: '24em', width: '100%', objectFit: 'cover' }} />
          </div>
          <div className="col-md-6">
            <div className="text-start">
              <h3 className="my-2">Welcome to Taxvise</h3>
              <p className="my-4">
                We are a modern firm with over a decade of experience in delivering comprehensive business services. Our expertise lies in simplifying complex tax and regulatory matters, streamlining accounting processes, and facilitating seamless business setup. We offer tailored solutions to help businesses thrive.
              </p>
              <a
                className="mt-4 text-primary relative group pb-1 hover:text-primary uppercase text-[13px] font-bold"
                href="/contact"
              >
                See All →
                <span className="absolute left-0 bottom-0 h-0.5 bg-primary transition-all duration-500 w-0 group-hover:w-full"></span>
              </a>
            </div>
          </div>
        </div>
        {latestServices.length !==0 && (
        <div className="row" style={{marginTop:'5em'}}>
          <div class=" mt-4 heading-section text-center ">
            <h2>We Can Help You With</h2>
          </div>
          {latestServices.map((item,index)=>(
          <div key={index} className="col-md-4">
            <div
              className="flex flex-col items-center w-[90%] mx-auto md:w-[30%] cursor-pointer mb-6"
              style={{ opacity: 1, transform: "none", willChange: "auto" }}
            >
              <div>
                <span style={{ fontSize: '5em', color: '#2b52a8' }} className="flaticon-accounting-1 "></span>
              </div>

              <h2 className="text-center text-gray-800 font-[500] mt-[15px]" style={{ fontSize: "21px" }}>
                {item.title}
              </h2>
              <p className="text-secondary mb-3 text-center text-md mt-[15px]">
                {item.description.slice(0,100)}...
              </p>
              <NavLink key={index}  to={`/services/${item.id}`} >
              <a
                className="mt-4 text-primary relative group pb-1 hover:text-primary uppercase text-[13px] font-bold"
              >
                Explore More →
                <span className="absolute left-0 bottom-0 h-0.5 bg-primary transition-all duration-500 w-0 group-hover:w-full"></span>
              </a>
              </NavLink>
            </div>
          </div>
         ))}
        </div>
        )}
      </div>
      <section
        className="ftco-section bg-light ftco-no-pt d-none"
        style={{ zIndex: "1" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4 ">
              <div className="d-block">
                <div className="icon d-flex mr-2">
                  <span className="flaticon-accounting-1"></span>
                </div>
                <div className="media-body">
                  <h3 className="heading">Accounting</h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4 ">
              <div className="d-block">
                <div className="icon d-flex mr-2">
                  <span className="flaticon-tax"></span>
                </div>
                <div className="media-body">
                  <h3 className="heading">Tax, Compliance &amp; Payroll</h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4 ">
              <div className="d-block">
                <div className="icon d-flex mr-2">
                  <span className="flaticon-loan"></span>
                </div>
                <div className="media-body">
                  <h3 className="heading">Financial Services</h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex services align-self-stretch px-4 ">
              <div className="d-block">
                <div className="icon d-flex mr-2">
                  <span className="flaticon-budget"></span>
                </div>
                <div className="media-body">
                  <h3 className="heading">Growth &amp; Funding Access</h3>
                  <p>
                    Even the all-powerful Pointing has no control about the
                    blind texts it is an almost unorthographic.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Feedback />

      <Faqs/>

      <NewsAndBlog />
      {/* <section class="ftco-section ftco-no-pb ftco-no-pt bg-secondary">
      <div class="container py-5">
    		<div class="row">
          <div class="col-md-7 d-flex align-items-center">
            <h2 class="mb-3 mb-sm-0" style={{color:'black',fontSize: '22px'}}>Sign Up for Your Free 1st Accounting Consultation</h2>
          </div>
          <div class="col-md-5 d-flex align-items-center">
            <form action="#" class="subscribe-form">
              <div class="form-group d-flex">
                <input type="text" class="form-control" placeholder="Enter email address"/>
                <input type="submit" value="Subscribe" class="submit px-3"/>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section> */}

      {/* <Packages/> */}
    </>
  );
}

export default Home;
