import React, { useEffect, useState } from "react";
import Axios from "axios";
import { BaseURL } from "../Components/Api";
function Faqs() {
  const baseurl = BaseURL();
  const [faqs, setGetFaqs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    GetFaqs();
  }, []);
  const GetFaqs = async () => {
    try {
      const response = await Axios.get(baseurl + `/api/faq`);
      if (response.data.status === true) {
        setIsLoading(false);
        setGetFaqs(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const steps = [
    {
      title: "Expert Knowledge",
      description: "Our team possesses deep expertise in Pakistani tax laws and business regulations, ensuring compliant services.",
    },
    {
      title: "Personalized Service",
      description: "We tailor our solutions to meet your specific business needs, providing a customized approach for optimal results.",
    },
    {
      title: "Comprehensive Solutions",
      description: "From business registration to streamlined accounting and tax compliance we offer a full range of services under one roof.",
    },
    {
      title: "Timely Assistance",
      description: "We prioritize punctuality, delivering services and solutions promptly to keep your business on track and compliant.",
    },
    {
      title: "Transparent Pricing",
      description: "Our clear and competitive pricing structure eliminates hidden costs, providing you with predictable and fair service fees.",
    },
    {
      title: "Proven Track Record",
      description: "With a history of satisfied clients, our firm has a demonstrated ability to deliver reliable and effective business solutions.",
    },
  ];
  return (
    <>
      <section class="ftco-section mt-4  ftco-no-pt bg-light ftco-faqs">
        <div class="container pt-4">
          <div class="row pt-4">
            <div class="col-lg-6">
              <div class="img-faqs w-100">
                <div class="img mb-4 mb-sm-0 faq-1"></div>
                <div class="img img-2 mb-4 mb-sm-0  home-about-2"></div>
              </div>
            </div>
            <div class="col-lg-6 pl-lg-5">
              <div class="heading-section mb-5 mt-5 mt-lg-0">
                <span class="subheading">FAQs</span>
                <h2 class="mb-3">Frequently Asks Questions</h2>
                <p>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                </p>
              </div>
              {faqs.length !== 0 ? (
                <div class="accordion text-start" id="accordionExample">
                  {/* {faqs.map((item, index) => {
                    const headingId = `heading${index}`;
                    const collapseId = `collapse${index}`;
                    return (
                      <div key={index} className="accordion-item">
                        <h2 className="accordion-header" id={headingId}>
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#${collapseId}`}
                            aria-expanded={index === 0 ? "true" : "false"}
                            aria-controls={collapseId}
                          >
                            {item.title}
                          </button>
                        </h2>
                        <div
                          id={collapseId}
                          className={`accordion-collapse collapse ${index === 0 ? "show" : ""}`}
                          aria-labelledby={headingId}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <div dangerouslySetInnerHTML={{ __html: item?.description }} />
                          </div>
                        </div>
                      </div>
                    );
                  })} */}
                  <div className="steps-container">
                    {faqs.map((step, index) => (
                      <div className="step" key={index} style={{ opacity: 1, transform: 'none', willChange: 'auto' }}>
                        <div className="min-h-8 min-w-8 max-h-8 bg-primary text-white d-flex justify-content-center align-items-center z-40">
                          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                          </svg>
                        </div>
                        <div>
                          <p className="step-title">
                            <b>{step.title}</b>
                          </p>
                          <div className="step-description" dangerouslySetInnerHTML={{ __html: step?.description }} />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        What services does TaxVise offer?
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        TaxVise provides a wide range of accounting and financial services, including taxation, tax returns, VAT returns, confirmation statements, tax planning, HMRC investigation advice, payroll registration, consultancy, workplace pensions, bookkeeping, statutory accounts, online accounting, and company management accounts.      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        How can I get started with TaxVise?
                      </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        You can get started by contacting us through our website, phone, or email. We’ll schedule an initial consultation to discuss your accounting needs and provide tailored solutions for your business.      </div>
                    </div>
                  </div>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                        How do you ensure the confidentiality of my financial information?
                      </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                      <div class="accordion-body">
                        We take data security and confidentiality very seriously. Our systems are fully compliant with GDPR and other data protection regulations, and we use the latest encryption technologies to safeguard your financial information.      </div>
                    </div>
                  </div>
                </div>
              )}

            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Faqs;