import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import Axios from "axios";
import { BaseURL } from "../Components/Api";
import Counter from '../Components/Counter'
import { NavLink, useLocation } from "react-router-dom";
import Helmet from "react-helmet";
import { Modal, Button } from 'rsuite';
function Services() {
  const baseurl = BaseURL();
  const location = useLocation();
  const [servicesList, setServicesList] = useState([]);
  const [servicesSelected, setServicesSelected] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (id) => {
    const filter = servicesList?.sub_services.filter(item => item.id === id);
    setServicesSelected(filter[0]);
    setOpen(true);
  }
  const handleClose = () => setOpen(false);
  const optionstop = {
    items: 3, // Number of items to display
    loop: true, // Loop through items
    margin: 10, // Margin between items
    animateOut: 'fadeOut', // Fade out animation
    animateIn: 'fadeIn', // Fade in animation
  };
  useEffect(() => {
    setIsLoading(true)
    getsevices();
  }, [location]);
  const getsevices = async () => {
    const url = window.location.href;

    // Extract the last part after the last "/"
    const lastPart = url.substring(url.lastIndexOf("/") + 1);
    try {
      const response = await Axios.get(baseurl + '/api/services/details/' + lastPart);
      console.log(response)
      if (response.data.status === true) {
        setIsLoading(false);
        setServicesList(response.data.data);
      }

    } catch (error) {
      console.error(error);
      console.log(error);
    }
  }
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>{servicesSelected.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{servicesSelected.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Helmet>
        <title>SERVICES</title>
      </Helmet>
      <section class="hero-wrap hero-wrap-2 services-top-img" data-stellar-background-ratio="0.5">
        <div class="overlay"></div>
        <div class="container">
          <div class="row no-gutters slider-text align-items-end">
            <div class="col-md-9 text-start pb-5">
              <p class="breadcrumbs mb-2"><span class="mr-2"><NavLink to="/">Home <i class="ion-ios-arrow-forward"></i></NavLink></span> <span>Services <i class="ion-ios-arrow-forward"></i></span></p>
              <h1 class="mb-0 bread">{servicesList?.title}</h1>
            </div>
          </div>
        </div>
      </section>
      <section class="mt-4 mb-4 text-start">
        <div class="container">
          <div class="row justify-content-center pb-2 mb-3">
            <div class="col-md-7 heading-section text-center ">
              {/* <span class="subheading">News &amp; Blog</span> */}
              <h2>Our Services</h2>
            </div>
          </div>
          {isLoading ? (
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2'>
                <div className='card-sl bootstrap-card-top '>
                  <div style={{ height: '13em', background: '#808080a1' }} className='card-image'>
                  </div>
                  <div className="hr-devider"></div>
                  <div className='row p-2'>
                    <h5 className="card-title placeholder-glow">
                      <span className="placeholder col-12"></span>
                      <span className="placeholder col-7"></span>
                      <span className="placeholder col-6"></span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2'>
                <div className='card-sl bootstrap-card-top '>
                  <div style={{ height: '13em', background: '#808080a1' }} className='card-image'>
                  </div>
                  <div className="hr-devider"></div>
                  <div className='row p-2'>
                    <h5 className="card-title placeholder-glow">
                      <span className="placeholder col-12"></span>
                      <span className="placeholder col-7"></span>
                      <span className="placeholder col-6"></span>
                    </h5>
                  </div>
                </div>
              </div>
              <div className='col-lg-4 col-md-4 col-sm-6 col-xs-12 mt-2'>
                <div className='card-sl bootstrap-card-top '>
                  <div style={{ height: '13em', background: '#808080a1' }} className='card-image'>
                  </div>
                  <div className="hr-devider"></div>
                  <div className='row p-2'>
                    <h5 className="card-title placeholder-glow">
                      <span className="placeholder col-12"></span>
                      <span className="placeholder col-7"></span>
                      <span className="placeholder col-6"></span>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {servicesList?.sub_services.length !== 0 ? (
                <div class="row">
                  {servicesList?.sub_services.map((item, index) => (
                    <div className="item col-md-4 col-sm-6 col-12" key={index}>
                      <div className="bootstrap-card-top">
                        <img src={item.media[0]?.original_url} alt="Card image cap" />
                        <div className="bootstrap-card-cody">
                          <h5 style={{cursor:'pointer'}} onClick={() => handleOpen(item.id)} className="fw-bold my-2 text-start service_title">{item.title}</h5>
                          <p >{item.description.slice(0, 100) + '...'}</p>
                          {/* <a href="#" class="">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center">
                  <h5>Sorry! No Service Found.</h5>
                </div>
              )}
            </>

          )}
        </div>
      </section>

      {/* <section className="ftco-section ftco-no-pt bg-light text-start">
        <div className="container">
          <div className="row d-flex no-gutters">
            <div className="col-md-6 d-flex">
              <div className="img home-about-image img-video d-flex align-self-stretch align-items-center justify-content-center justify-content-md-center mb-4 mb-sm-0"></div>
            </div>
            <div className="col-md-6 pl-md-5 py-md-5">
              <div className="heading-section pl-md-4 pt-md-5">
                <span className="subheading">Welcome to Accounting</span>
                <h2 className="mb-4">We Are the Best Accounting Agency</h2>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="flaticon-wealth"></span>
                </div>
                <div className="text pl-4">
                  <h4>Market Analysis</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="flaticon-accountant"></span>
                </div>
                <div className="text pl-4">
                  <h4>Accounting Advisor</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="flaticon-teamwork"></span>
                </div>
                <div className="text pl-4">
                  <h4>General Consultancy</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia
                  </p>
                </div>
              </div>
              <div className="services-2 w-100 d-flex">
                <div className="icon d-flex align-items-center justify-content-center">
                  <span className="flaticon-accounting"></span>
                </div>
                <div className="text pl-4">
                  <h4>Structured Assestment</h4>
                  <p>
                    Far far away, behind the word mountains, far from the
                    countries Vokalia and Consonantia
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  )
}
export default Services;